import React, { useState } from "react";

const SubscribeForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!emailRegex.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      const response = await fetch(
        "https://hooks.zapier.com/hooks/catch/9360354/32aeyvy/",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email
          })
        }
      );
      setEmail("");
      setSuccessMessage("Thank you for subscribing!");
      setErrorMessage("");
    } catch (error) {
      setEmail("");
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="example@trustedtechteam.com"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>
      <button type="submit">Subscribe</button>
      {successMessage && <p style={successStyles}>{successMessage}</p>}
      {errorMessage && <p style={errorStyles}>{errorMessage}</p>}
    </form>
  );
};


const successStyles: React.CSSProperties = {
  color: "#60c659",
  fontSize: "1.2rem"
};

const errorStyles: React.CSSProperties = {
  color: "#ff0000",
  fontSize: "1.2rem"
};

export default SubscribeForm;

