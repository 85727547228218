import { lighten } from 'polished';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { colors } from '../../styles/colors';
import SubscribeForm from './SubscribeForm';

const SubscribeFormSection = styled.section`
  margin: 1.5em 0;
  padding: 6.5vw 7vw 7vw;
  text-align: center;
  background: var(--postBg);
  border-radius: 7px;

  p {
    margin-bottom: 1em;
    color: var(--textNormal);
    font-size: 2.2rem;
    line-height: 1.55em;
    letter-spacing: 0.2px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  form label {
    display: none;
  }

  form input {
    background-color: #ffffff;
    color: #1a1a1a;
    padding: 1rem;
    border-radius: 200px;
    border: 1px solid var(--textNormal);
    width: 50%;
  }

  form button {
    display: block;
    position: relative;
    z-index: 99999;
    background-color: #60c659;
    border-radius: 200px;
    color: #1a1a1a;
    padding: 1.3rem 2rem;
    margin: 1rem auto;
    font-weight: 400;
    font-family: 'Rubik';
    letter-spacing: 2px;
    transition: all .15s ease-in-out;
    text-transform: uppercase;

    :hover {
      color: #ffffff;
    }
  }

  .form-group {
    flex-grow: 1;
    width: 100%;
  }
  @media (max-width: 650px) {
    p {
      font-size: 1.6rem;
    }
    form input {
      width: 100%;
    }
  }
`;

const SubscribeFormTitle = css`
  margin: 0 0 3px 0;
  padding: 0;
  color: var(--postTitle);
  font-size: 3.5rem;
  line-height: 1;
  font-weight: 700;
  @media (max-width: 650px) {
    font-size: 2.4rem;
  }
`;

export interface SubscribeProps {
  title: string;
}

const Subscribe: React.FC<SubscribeProps> = props => {
  return (
    <SubscribeFormSection>
      <h3 css={SubscribeFormTitle}>Subscribe to the {props.title}</h3>
      <p>Get the latest posts delivered right to your inbox</p>
      <SubscribeForm />
    </SubscribeFormSection>
  );
};

export default Subscribe;
