import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import * as _ from 'lodash';
import { position, setLightness } from 'polished';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Helmet } from 'react-helmet';

import AuthorCard from '../components/AuthorCard';
import Footer from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import PostCard from '../components/PostCard';
import PostContent from '../components/PostContent';
import PostFullFooter from '../components/PostFullFooter';
import PostFullFooterRight from '../components/PostFullFooterRight';
import PostCTA from '../components/PostCTA';
import WinServCTA from '../components/WinServCTA';

import ReadNextCard from '../components/ReadNextCard';
import Subscribe from '../components/subscribe/Subscribe';
import Wrapper from '../components/Wrapper';
import IndexLayout from '../layouts';
import { colors } from '../styles/colors';
import { inner, outer, SiteHeader, SiteMain, CommentBox } from '../styles/shared';
import config from '../website-config';
import { TinyButton as ScrollUpButton } from 'react-scroll-up-button';
import Burger from '../components/BurgerMenu';
import { Disqus, CommentCount } from 'gatsby-plugin-disqus';
import { pathToFileURL } from 'url';

import backgroundWinServCTA from '../content/img/ws-2022-standard_ttt2.jpg';

const PostTemplate = css`
  .site-main {
    padding-bottom: 4vw;
    z-index: 98;
  }
  .gatsby-resp-image-figcaption {
    text-align: center;
    margin-top: 10px;
    color: var(--textStyled);
  }
`;

const PostContentFooter = styled.div`
margin: 0 auto 3rem;
padding: 0px 100px;
font-family: 'Lato','Helvetica', Georgia, serif;
font-size: 2.2rem;
line-height: 1.6em;

@media (max-width: 1170px) {
  padding: 0 7vw;
}

@media (max-width: 800px) {
  font-size: 1.9rem;
}

@media (max-width: 500px) {
  padding: 0;
}

a {
  color: var(--textNormal);
  word-break: break-word;
  text-decoration: none;
  transition: background 500ms ease;
  background-image: linear-gradient(transparent 50%, var(--textLink) 50%);
  background-size: 2px;
  background-size: auto 175%;
}

a:hover {
  background-position-y: 110%;
      
}

p {
  font-size: 80%;
  line-height: 1.6em;
}

strong,
em {
  color: var(--textStyled);
}

a em {
  color: var(--textNormal);
}

small {
  display: inline-block;
  line-height: 1.6em;
}

hr {
  margin: 4vw 0;
}

hr:after {
  content: '';
  position: absolute;
  top: -15px;
  left: 50%;
  display: block;
  margin-left: -10px;
  width: 1px;
  height: 30px;
  box-shadow: color(var(--bg));
  transform: rotate(45deg);
}
`;

export const PostFull = css`
  position: relative;
  z-index: 50;
`;

export const NoImage = css`
  .post-full-content {
    padding-top: 0;
  }

  .post-full-content:before,
  .post-full-content:after {
    display: none;
  }
`;

export const PostFullHeader = styled.header`
  margin: 0 auto;
  padding: 6vw 3vw 3vw;
  max-width: 1040px;
  text-align: center;

  @media (max-width: 500px) {
    padding: 14vw 3vw 10vw;
  }
  @media (max-width: 700px) {
    margin-top: 60px;
  }
`;

const PostFullMeta = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.midgrey};
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;

  @media (max-width: 500px) {
    font-size: 1.2rem;
    line-height: 1.3em;
  }
`;

const PostFullMetaDate = styled.time`
  color: var(--textLink);
`;

export const PostFullTitle = styled.h1`
  margin: 0;
  font-family: 'Rubik';
  font-weight: 700;
  text-transform: uppercase;
  color: var(--textTitle);
  @media (max-width: 500px) {
    font-size: 2.9rem;
  }
`;

const PostFullImage = styled.figure`
  margin: 0 -10vw -165px;
  height: 800px;
  background: var(--bg) center center;
  background-size: cover;
  border-radius: 5px;

  @media (max-width: 1170px) {
    margin: 0 -4vw -100px;
    height: 600px;
    border-radius: 0;
  }

  @media (max-width: 800px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    margin-bottom: 4vw;
    height: 290px;
  }
`;

const DateDivider = styled.span`
  display: inline-block;
  margin: 0 6px 1px;
`;

const ReadNextFeed = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;
`;

const ExitIntentPopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.82);
  z-index: 99999;
  align-items: center;
`;

const ExitIntentPopup = styled.div`
  background: #ffffff;
  color: #1a1a1a;
  width: 400px;
  height: auto;
  text-align: center;
  position: fixed;
  inset: 81.5px auto auto 750px;

  @media only screen and (min-width: 960px) {
    inset: 81.5px auto auto 520px;
  }
  @media only screen and (min-width: 1440px) {
    inset: 81.5px auto auto 730px;
  }
  @media only screen and (min-width: 2000px) {
    inset: 81.5px auto auto 1060px;
  }
`;

const ExitIntentPopupCloseBtbn = styled.div`
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
`;

const ExitIntentPopUpRCol = styled.div`
  padding: 45px;
`;

const ExitIntentPopUpCalendlyBtn = styled.button`
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  background: #60c659;
  color: #353842;
  border: 0;
  border-radius: 5px;
  padding: 10px 16px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;

  :hover {
    background: #666;
    color: #fff;
    border: 1px solid #666;
  }
`;

const ExitIntentPopUpLicenseBtn = styled.button`
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  background: 0 0;
  border: 1px solid #666;
  color: #666;
  border-radius: 5px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;

  :hover {
    background: #666;
    color: #fff;
    border: 1px solid #666;
  }
`;

interface PageTemplateProps {
  pathContext: {
    slug: string;
  };
  data: {
    logo: {
      childImageSharp: {
        fixed: any;
      };
    };
    markdownRemark: {
      html: string;
      htmlAst: any;
      excerpt: string;
      timeToRead: string;
      frontmatter: {
        title: string;
        date: string;
        userDate: string;
        image: {
          childImageSharp: {
            fluid: any;
          };
        };
        tags: string[];
        customFooter: string;
        author: {
          id: string;
          bio: string;
          avatar: {
            children: Array<{
              fixed: {
                src: string;
              };
            }>;
          };
        };
        canonical: string;
      };
    };
    relatedPosts: {
      totalCount: number;
      edges: Array<{
        node: {
          timeToRead: number;
          frontmatter: {
            title: string;
          };
          fields: {
            slug: string;
          };
        };
      }>;
    };
  };
  pageContext: {
    prev: PageContext;
    next: PageContext;
  };
}

export interface PageContext {
  excerpt: string;
  timeToRead: number;
  fields: {
    slug: string;
  };
  frontmatter: {
    image: {
      childImageSharp: {
        fluid: any;
      };
    };
    title: string;
    date: string;
    userDate: string;
    draft?: boolean;
    tags: string[];
    customFooter: string;
    author: {
      id: string;
      bio: string;
      avatar: {
        children: Array<{
          fixed: {
            src: string;
          };
        }>;
      };
    };
    canonical: string;
  };
}

const MarkdownRenderer = ({ content }: { content: string }) => {
  return <ReactMarkdown>{content}</ReactMarkdown>;
};

const PageTemplate: React.FC<PageTemplateProps> = (props) => {
  const post = props.data.markdownRemark;
  let width = '';
  let height = '';
  if (post.frontmatter.image && post.frontmatter.image.childImageSharp) {
    width = post.frontmatter.image.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
    height = String(Number(width) / post.frontmatter.image.childImageSharp.fluid.aspectRatio);
  }

  let disqusConfig = {
    url: `${config.siteUrl + props.pathContext.slug}`,
    identifier: post.frontmatter.title,
    title: post.frontmatter.title,
  };

  let url = typeof window !== 'undefined' ? window.location.href : '';
  const [isShown, setIsShown] = useState(false);

  function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + '=';
    var begin = dc.indexOf('; ' + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    } else {
      begin += 2;
      var end = document.cookie.indexOf(';', begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  var handleExitIntentPopup = () => {
  };

  // if(url === 'http://localhost:8000/2021-08-23-windows-server-2022-top-features/'){
  if ( url ===
    'https://blog.trustedtechteam.com/2021-08-23-windows-server-2022-top-features/'
  ) {
    // console.log('URL: ', window.location.href );

    setTimeout(() => {
      if (isShown) {
        const expiryDate = new Date(Date.now() + 14 * (1000 * 60 * 60 * 24));
        expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        document.cookie = 'modal_seen' + '=true; expires=' + expiryDate.toUTCString();
      }
    }, 5000);

    handleExitIntentPopup = () => {
      var myCookie = getCookie('modal_seen');
      if (myCookie == null) {
        setIsShown(true);
      } else {
        setIsShown(false);
      }
    };
  }

  useEffect(() => {
    var attempts = 0;
    const checkForAds = () => {
      const disqusAd = document.querySelector('#disqus_thread iframe:nth-child(1)');
      
      if (disqusAd) {
        disqusAd.style.display = 'none';
      } else {
        attempts += 1;
        (attempts < 10) && setTimeout(checkForAds, 1000);
      }
    };
    
    setTimeout(checkForAds, 1000);

  }, [isShown]);

  // START SNIPPET: Article Structured Data
  var includeArticleStructuredData = false;
  if (
    url.includes("/2023-01-03-three-areas-where-azure-will-surpass-aws-in-2023/") || 
    url.includes("/2022-05-09-server-2022-datacenter-azure-edition-all-you-need-to-know/") ||
    url.includes("/2020-12-22-is-breaking-up-with-azure-hard-to-do-how-to-delete-your-azure-account/") ||
    url.includes("/2018-09-07-12-pros-and-cons-of-slack-vs-teams/") ||
    url.includes("/2022-02-22-user-cals-vs-device-cals/") ||
    url.includes("/2021-10-11-microsoft-project-vs-jira-pros-cons/") ||
    url.includes("/2020-12-29-sql-deadlocks-why-they-happen-how-to-avoid-them/") ||
    url.includes("/2021-08-23-windows-server-2022-top-features/") ||
    url.includes("/2023-02-06-m365-e3-vs-e5-which-is-best-for-you/") ||
    url.includes("/2021-03-19-azure-pipeline-variables-what-you-need-to-know/")
  ) {
    includeArticleStructuredData = true;
  }

  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": post.frontmatter.title,
    "image": [
      post.frontmatter.image.childImageSharp.fluid.src
    ],
    "datePublished": post.frontmatter.date,
    "author": [
      {
        "@type": "Person",
        "name": post.frontmatter.author.id,
        "url": `/author/${_.kebabCase(post.frontmatter.author.id)}/`
      }
    ]
  };
  // END SNIPPET

  return (
    <IndexLayout className="post-template">
      <Burger />
      <ScrollUpButton
        style={{
          fill: 'var(--textLink)',
          background: 'none',
          left: '20px',
        }}
      />
      <Helmet>
        <html lang={config.lang} />
        <title>{post.frontmatter.title}</title>

        {/* START SNIPPET: Article Structured Data */}
        {includeArticleStructuredData && 
          <script type="application/ld+json">
            {JSON.stringify(articleStructuredData)}
          </script>
        }
        {/* END SNIPPET */}

        {/* adds canonical link if in frontmatter */}
        {post.frontmatter.canonical && <link rel="canonical" href={post.frontmatter.canonical} />}
        <meta name="description" content={post.excerpt} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:url" content={config.siteUrl + props.pathContext.slug} />
        {post.frontmatter.image && post.frontmatter.image.childImageSharp && (
          <meta
            property="og:image"
            content={`${config.siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`}
          />
        )}
        <meta property="article:published_time" content={post.frontmatter.date} />
        {/* not sure if modified time possible */}
        {/* <meta property="article:modified_time" content="2018-08-20T15:12:00.000Z" /> */}
        {post.frontmatter.tags && (
          <meta property="article:tag" content={post.frontmatter.tags[0]} />
        )}

        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.facebook && <meta property="article:author" content={config.facebook} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta name="twitter:description" content={post.excerpt} />
        <meta name="twitter:url" content={config.siteUrl + props.pathContext.slug} />
        {post.frontmatter.image && post.frontmatter.image.childImageSharp && (
          <meta
            name="twitter:image"
            content={`${config.siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`}
          />
        )}
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content={post.frontmatter.author.id} />
        <meta name="twitter:label2" content="Filed under" />
        {post.frontmatter.tags && <meta name="twitter:data2" content={post.frontmatter.tags[0]} />}
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        {config.twitter && (
          <meta
            name="twitter:creator"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        {width && <meta property="og:image:width" content={width} />}
        {height && <meta property="og:image:height" content={height} />}
      </Helmet>
      <Wrapper css={PostTemplate}>
        <header css={[outer, SiteHeader]}>
          <div onMouseEnter={() => handleExitIntentPopup()}>
            <SiteNav />
          </div>
        </header>
        <main
          className="site-main"
          css={[SiteMain, outer]}
          style={{
            backgroundColor: 'var(--postBg)',
            color: 'var(--textNormal)',
            transition: 'color -.2s ease-out, background 0.2 ease-out',
          }}
        >
          <div css={inner}>
            {/* TODO: no-image css tag? */}
            <article css={[PostFull, !post.frontmatter.image && NoImage]}>
              <PostFullHeader
                style={{
                  backgroundColor: 'var(--postBg)',
                  color: 'var(--textNormal)',
                  transition: 'color -.2s ease-out, background 0.2 ease-out',
                }}
              >
                <PostFullMeta>
                  <PostFullMetaDate dateTime={post.frontmatter.date}>
                    {post.frontmatter.userDate}
                  </PostFullMetaDate>
                  {post.frontmatter.tags && post.frontmatter.tags.length > 0 && (
                    <>
                      <DateDivider>/</DateDivider>
                      <Link
                        style={{ color: 'var(--textLink)' }}
                        to={`/tags/${_.kebabCase(post.frontmatter.tags[0])}/`}
                      >
                        {post.frontmatter.tags[0]}
                      </Link>
                    </>
                  )}
                </PostFullMeta>
                <PostFullTitle>{post.frontmatter.title}</PostFullTitle>
              </PostFullHeader>

              {post.frontmatter.image && post.frontmatter.image.childImageSharp && (
                <PostFullImage>
                  <Img
                    style={{ height: '100%' }}
                    fluid={post.frontmatter.image.childImageSharp.fluid}
                  />
                </PostFullImage>
              )}
              <WinServCTA />
              <PostCTA />
              <ExitIntentPopupOverlay
                style={{
                  display: isShown ? 'block' : 'none',
                  // color: 'var(--textNormal)',
                }}
              >
                <ExitIntentPopup
                  style={{
                    display: isShown ? 'block' : 'none',
                    // color: 'var(--textNormal)',
                  }}
                >
                  <ExitIntentPopupCloseBtbn
                    style={{
                      display: isShown ? 'block' : 'none',
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      style={{
                        width: '20px',
                        height: '20px',
                        marginTop: '-10px',
                        marginRight: '-10px',
                        position: 'absolute',
                        top: '50%',
                        right: '50%',
                        fill: 'rgb(169, 169, 169)',
                      }}
                      onClick={() => setIsShown(false)}
                    >
                      <path d="M22.2,4c0,0,0.5,0.6,0,1.1l-6.8,6.8l6.9,6.9c0.5,0.5,0,1.1,0,1.1L20,22.3c0,0-0.6,0.5-1.1,0L12,15.4l-6.9,6.9c-0.5,0.5-1.1,0-1.1,0L1.7,20c0,0-0.5-0.6,0-1.1L8.6,12L1.7,5.1C1.2,4.6,1.7,4,1.7,4L4,1.7c0,0,0.6-0.5,1.1,0L12,8.5l6.8-6.8c0.5-0.5,1.1,0,1.1,0L22.2,4z"></path>
                    </svg>
                  </ExitIntentPopupCloseBtbn>
                  <ExitIntentPopUpRCol
                    style={{
                      display: isShown ? 'block' : 'none',
                      // color: 'var(--textNormal)',
                    }}
                  >
                    <img src="https://cdn.shopify.com/s/files/1/1694/1769/files/ws-2022-standard_ttt2.jpg?v=1633632269" alt="" />
                    <h3>Ready to upgrade?</h3>
                    <div
                      style={{
                        margin: '10px',
                        padding: '6px',
                      }}
                    >
                      Get started today with an early adopter <br />
                      $10 OFF discount code: <b>Early-22</b>
                      <br />
                      (Valid for Windows Server 2022 Standard and Datacenter)
                    </div>
                    <a
                      href="https://www.trustedtechteam.com/discount/Early-22?redirect=/collections/windows-server/2022"
                      target="_blank"
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <ExitIntentPopUpCalendlyBtn>Apply Discount</ExitIntentPopUpCalendlyBtn>
                    </a>
                    <a href="https://www.trustedtechteam.com/pages/calculator" target="blank">
                      <ExitIntentPopUpLicenseBtn>Assess my Costs</ExitIntentPopUpLicenseBtn>
                    </a>
                  </ExitIntentPopUpRCol>
                </ExitIntentPopup>
              </ExitIntentPopupOverlay>
              <PostContent htmlAst={post.htmlAst} />
              {/* <CommentCount config={disqusConfig} placeholder={'...'} /> */}
              <PostContentFooter>
                <hr></hr>
                {post.frontmatter.customFooter ? (
                  <MarkdownRenderer content={post.frontmatter.customFooter} />
                ) : (
                  <div>
                    <p>
                      Trusted Tech Team is an accredited Microsoft CSP Direct Bill Partner, carrying multiple Solutions Partner designations and the now-legacy Microsoft Gold Partner competency. Based in Irvine, California, we report trends affecting IT pros everywhere.
                    </p>
                    <p>
                      If your organization uses Microsoft 365 or Azure, you may be eligible to receive a complimentary savings report from a Trusted Tech Team Licensing Engineer. <a href="https://www.trustedtechteam.com/pages/contact-us">Click here to schedule a consultation with our team now</a> to learn how much you can save today.  
                    </p>
                  </div>
                )}
              </PostContentFooter>
              <Disqus config={disqusConfig} />

              {/* The big email subscribe modal content */}
              {config.showSubscribe && <Subscribe title={config.title} />}

              <PostFullFooter>
                <AuthorCard author={post.frontmatter.author} />
                <PostFullFooterRight authorId={post.frontmatter.author.id} />
              </PostFullFooter>
            </article>
          </div>
        </main>

        {/* Links to Previous/Next posts */}
        <aside className="read-next" css={outer}>
          <div css={inner}>
            <ReadNextFeed>
              {props.data.relatedPosts && (
                <ReadNextCard tags={post.frontmatter.tags} relatedPosts={props.data.relatedPosts} />
              )}

              {props.pageContext.prev && <PostCard post={props.pageContext.prev} />}
              {props.pageContext.next && <PostCard post={props.pageContext.next} />}
            </ReadNextFeed>
          </div>
        </aside>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default PageTemplate;

export const query = graphql`
  query($slug: String, $primaryTag: String) {
    logo: file(relativePath: { eq: "img/ttt-blog_banner.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      excerpt
      timeToRead
      frontmatter {
        title
        userDate: date(formatString: "D MMMM YYYY")
        date
        tags
        customFooter
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author {
          id
          bio
          avatar {
            children {
              ... on ImageSharp {
                fixed(quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        canonical
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: { frontmatter: { tags: { in: [$primaryTag] }, draft: { ne: true } } }
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          frontmatter {
            title
            userDate: date(formatString: "D MMMM YYYY")
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
