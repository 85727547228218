//WinServCTA.tsx

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import backgroundCTA from './da_graphik.png';
import backgroundWinServCTA from '..content/img/ws-2022-standard_ttt2.jpg';

const WinServCTAStyle = styled.div`
  position: fixed;
  background: var(--bg);
  box-shadow: var(--boxShadow) 8px 0px 7px, var(--boxShadow) 0px 10px 10px;
  border-radius: 7px;
  padding: 2rem 1.8rem;;
  height: 480px;
  width: 300px;
  text-align: center;
  top: 10rem;
  z-index: 99999;
  right: 10rem;
  a {
    color: black;
  }
  ::before {
    background: var(--bg);
    padding: 2rem 1.8rem;;
    position: fixed;
    height: 480px;
    width: 300px;

    content: '';

    border-radius: 7px; /*1*/
    border: 7px solid transparent; /*2*/
    background: linear-gradient(45deg, #60c659, #3eecac) border-box; /*3*/
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; /*5'*/
    mask-composite: exclude; /*5*/

    text-align: center;
    top: 10rem;
    z-index: 99999;
    right: 10rem;
    @media (max-width: 1840px) {
      right: 5rem;
    }
    @media (max-width: 1739px) {
      visibility: hidden;
    }
  }

  display: none;
  @media (max-width: 1840px) {
    right: 5rem;
  }
  @media (max-width: 1739px) {
    visibility: hidden;
  }
`;

const WinServCTAbutton = styled.button`
  display: block;
  background-color: #60c659;
  border-radius: 50px;
  color: white;
  padding: 1rem;
  font-weight: 500;
  width: 100%;
  margin-top: 1.5rem;
  cursor: pointer;
`;

const WinServCTA: React.FC = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const url = typeof window !== "undefined"  ? window.location.href : '';
    
    // if(url === 'http://localhost:8000/2021-08-23-windows-server-2022-top-features/'){
    if(url === 'https://blog.trustedtechteam.com/2021-08-23-windows-server-2022-top-features/'){
    // console.log('URL: ', window.location.href );

    var handleScroll = () => {
        // find current scroll position
        const currentScrollPos = window.pageYOffset;
    
        // set state based on location info
        setVisible(
          (prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 3000) ||
            currentScrollPos < 1020,
        );
    
        // set state to new scroll position
        setPrevScrollPos(currentScrollPos);
      };
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

    return (
      <a
        target="_blank"
        href="https://www.trustedtechteam.com/collections/windows-server/2022"
        style={{ color: 'black' }}
      >
        <WinServCTAStyle
          style={{
            display: visible ? 'none' : 'block',
            color: 'var(--textNormal)',
          }}
        >
          <img
            src="https://cdn.shopify.com/s/files/1/1694/1769/files/ws-2022-standard_ttt2.jpg?v=1633632269"
            style={{
              paddingTop: '8px',
            }}
          />
          <h4 style={{ fontWeight: 'bold' }}>Looking for detailed specs?</h4>
          <div style={{ fontSize: '1.4rem', lineHeight: '1.8rem' }}>
            Browse the Windows Server 2022 collection and be sure to use the early adopter $10 OFF
            discount code: Early-22
          </div>
          <WinServCTAbutton>Learn More</WinServCTAbutton>
        </WinServCTAStyle>
      </a>
    );
  };

export default WinServCTA;
