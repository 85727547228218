import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import backgroundCTA from './da_graphik.png';
import backgroundWinServCTA from './ws-2022-standard_ttt2.jpg';

const PostCTAStyle = styled.div`
  position: fixed;
  background: var(--bg);
  box-shadow: var(--boxShadow) 8px 0px 7px, var(--boxShadow) 0px 10px 10px;
  border-radius: 8px;
  padding: 0.8rem 2.25rem;
  height: 400px;
  width: 300px;
  text-align: center;
  z-index: 99999;
  top: 10vw;
  right: 5vw;
  a {
    color: black;
  }
  ::before {
    background: var(--bg);
    padding: 0.8rem 2.25rem;
    position: fixed;
    height: 400px;
    width: 300px;
    content: '';
    border-radius: 8px; /*1*/
    border: 8px solid transparent; /*2*/
    background: linear-gradient(45deg, #60c659, #3eecac) border-box; /*3*/
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; /*5'*/
    mask-composite: exclude; /*5*/

    text-align: center;
    top: 10vw;
    right: 5vw;
    z-index: 99999;
    @media (max-width: 1739px) {
      visibility: hidden;
    }
  }
  display: none;
  @media (max-width: 1739px) {
    visibility: hidden;
  }

`;

const WinServCTAStyle = styled.div`
  position: fixed;
  background: var(--bg);
  box-shadow: var(--boxShadow) 8px 0px 7px, var(--boxShadow) 0px 10px 10px;
  border-radius: 7px;
  padding: 0.8rem 2.25rem;
  height: 480px;
  width: 300px;
  text-align: center;
  top: 10rem;
  z-index: 99999;
  right: 10rem;
  a {
    color: black;
  }
  ::before {
    background: var(--bg);
    padding: 0.8rem 2.25rem;
    position: fixed;
    height: 480px;
    width: 300px;

    content: '';

    border-radius: 7px; /*1*/
    border: 7px solid transparent; /*2*/
    background: linear-gradient(45deg, #60c659, #3eecac) border-box; /*3*/
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; /*5'*/
    mask-composite: exclude; /*5*/

    text-align: center;
    top: 10rem;
    z-index: 99999;
    right: 10rem;
    @media (max-width: 1840px) {
      right: 5rem;
    }
  }

  display: none;
  @media (max-width: 1840px) {
    right: 5rem;
  }
  @media (max-width: 1739px) {
    visibility: hidden;
  }
`;

const PostCTAbutton = styled.button`
  display: block;
  position: relative;
  z-index: 99999;
  background-color: #60c659;
  border-radius: 5px;
  color: #1a1a1a;
  padding: 1.3rem 2rem;
  margin: 1rem auto;
  font-weight: 400;
  font-family: 'Rubik';
  letter-spacing: 2px;
  transition: all .15s ease-in-out;
  text-transform: uppercase;

  :hover {
    color: #ffffff;
  }
`;

const PostCTA: React.FC = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const url = typeof window !== "undefined"  ? window.location.href : '';

    // if(url !== 'http://localhost:8000/2021-08-23-windows-server-2022-top-features/'){
    if( url !== 'https://blog.trustedtechteam.com/2021-08-23-windows-server-2022-top-features/'){
    // console.log('URL: ', window.location.href );

    var handleScroll = () => {
        // find current scroll position
        const currentScrollPos = window.pageYOffset;
    
        // set state based on location info
        setVisible(
          (prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 3000) ||
            currentScrollPos < 1020,
        );
    
        // set state to new scroll position
        setPrevScrollPos(currentScrollPos);
      };
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <a
      target="_blank"
      href="https://www.trustedtechteam.com/pages/contact-us"
      style={{ color: 'black' }}
    >
      <PostCTAStyle
        style={{
          display: visible ? 'none' : 'block',
          color: 'var(--textNormal)',
        }}
      >
        <img
          src={backgroundCTA}
          style={{
            paddingTop: '8px',
          }}
        />
        <h4 style={{ fontWeight: 'bold' }}>Have questions about licensing?</h4>
        <div style={{ fontSize: '1.4rem', lineHeight: '1.8rem' }}>
          Talk to a licensing engineer today to help find the right software solution for you.
        </div>
        <PostCTAbutton>Contact Us</PostCTAbutton>
      </PostCTAStyle>
    </a>
  );
};

export default PostCTA;
